.chatbot-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  font-family: Arial, sans-serif;
}

.chat-and-data-container {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.chat-window {
  width: 30%;
  padding: 10px;
  overflow-y: auto;
  background-color: #f9f9f9;
  border-right: 1px solid #ccc;
}

.chat-message {
  padding: 10px;
  margin: 5px 0;
  border-radius: 8px;
  max-width: 80%;
}

.chat-message.user {
  align-self: flex-end;
  background-color: #dcf8c6;
}

.chat-message.bot {
  align-self: flex-start;
  background-color: #fff;
  border: 1px solid #ccc;
}

.right-response {
  width: 70%;
  padding: 10px;
  background-color: #fff;
  overflow-y: auto;
}

.right-response pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.right-response table {
  width: 100%;
  border-collapse: collapse;
}

.right-response th,
.right-response td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.loader {
  font-size: 20px;
  text-align: center;
  padding: 20px;
}

.chat-input {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background-color: #fff;
  border-top: 1px solid #ccc;
}

.chat-input input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.chat-input button {
  margin-left: 10px;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.chat-input button:hover {
  background-color: #0056b3;
}
